<script>
import { mainStore } from '@/store/index'

export default {
  name: 'App',
  setup(){
    const store = mainStore();
    return {
      store
    };
  },
  data() {
    return {
      gameSetup: {},
      showInfo: false
    }
  },
  methods: {
    setupGame(val) {
      this.gameSetup.completed = true;
    },
    showImprint(){
      let text = "This randomizer is brought to you by B!Have. \n\nB!Have\nMartin-Luther-Platz 15 \n91126 Schwabach\nGermany \n\nImages are coming from: \n C86 @ Tagz \nEarth and moon: Nasa\n"
      alert(text);
    }
  }
}


</script>
<script setup>
import Game from '@/components/Game'
import GameSetup from "@/views/GameSetup";
</script>

<template>
  <h1 style="font: 30px/25px 'Darker Grotesque', sans-serif; color: white; font-weight: bold; position: absolute; top: 10px; left:30px;">Cydog86 <br/> Race</h1>
  <div id="game">
    <GameSetup @setupCompleted="setupGame" v-if="!gameSetup.completed"/>
    <Game v-if="gameSetup.completed"/>
  </div>
  <span style="text-decoration: underline;margin: 0 auto; font-family: sans-serif; color: white; font-size: 1.5em; width: 20%; display: flex; justify-content: space-around; align-items: center;">
    <a @click="showInfo = !showInfo" style="text-decoration: underline;margin: 0 auto; font-family: sans-serif; color: white;">Info</a> |
    <a @click="showImprint" style="text-decoration: underline;margin: 0 auto; font-family: sans-serif; color: white; ">Impress</a>
  </span>
  <p v-if="showInfo" style=" width: 40%; margin: 0 auto; font-family: sans-serif; color: white; font-size: 1.5em; padding-top: 20px; margin-bottom: 20px;">Start the Race with Enter key! Cydog Race is a randomizer. It utilizes the Browsers crypto.getRandomValues() method on different occasion, such as time to update the speed or the speed of an object itself. If you like to have a custom tailored, race like randomizer for your NFT project, hopp over to the <a href="https://discord.gg/jwwCwkA3vc" style="color: white;">Wasted Whales Discord</a> and ask for @BHave.
  <br/>
    If you have any suggestions head over to the Wasted Whales or C86 discord server and ping me (@BHave). This is a work in progress (Procastination project). I will add more animations, functions to it as soon as I have time to spend on it.
  </p>

</template>

<style lang="scss">
body {
  height: 120vh;
  min-height: 1100px;
  margin: 0;
  background: black;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: black;
}

#game{
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
